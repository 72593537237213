<template>
    <!-- 导航 -->
    <page-head title="机构介绍"/>
    <content-bg>
        <template v-slot:content>
            <div class="content">
                <p class="title">机构介绍</p>
                <p class="introduceText">
                    为深入贯彻党中央、国务院关于足球改革发展的决策部署，进一步推动落实《中国足球改革发展总体方案》，
                    谋深做实“十四五”体育发展规划中的足球专项规划，体育总局、中国足协决定联合开展《全国足球发展重点城市》建设工作，
                    选择一批足球基础好、发展条件好、工作积极性高的城市，加强扶持和指导，总结推广足球改革发展的典型经验，探索中国特色足球发展路径，推动我国足球水平整体提高.
                </p>
                <div class="list">
                    <dl>
                        <dt @click="goMoment">精彩瞬间 <img src="@i/introduce/go.svg" alt=""></dt>
                        <dd><img src="@i/introduce/test.jpg" alt=""></dd>
                    </dl>
                    <dl>
                        <dt @click="goVideo">视频资料 <img src="@i/introduce/go.svg" alt=""></dt>
                        <dd><img src="@i/introduce/test2.jpg" alt=""></dd>
                    </dl>
                </div>
            </div>
        </template>
    </content-bg>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "introduce",

    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({});

        const goMoment = () => {
            router.push({
                path: '/introduce/moment',
                name: 'moment'
            })
        }

        const goVideo = () => {
            router.push({
                path: '/introduce/video',
                name: 'videoIntroduce'
            })
        }

        return {
            goMoment,
            goVideo
        }

    },
}
</script>

<style scoped lang="scss">
.content {
    padding-left: 100px;
    padding-right: 100px;
    box-sizing: border-box;
}

.title {
    padding-top: 44px;
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}

.introduceText {
    font-family: PingFang;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: rgba(255, 255, 255, .8);
    text-indent: 50px;
    letter-spacing: 2px;
}

.list {
    margin-top: 20px;

    dl {
        text-align: center;
        font-family: PingFang;
        font-size: 24px;
        color: rgba(255, 255, 255);
        width: 48%;

        &:first-child {
            float: left;
        }

        &:last-child {
            float: right;
        }

        dt {
            height: 66px;
            line-height: 66px;
            background: linear-gradient(180deg, #0F1016 0%, rgba(15, 16, 22, 0.7) 100%);
            backdrop-filter: blur(27px);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
                margin-left: 10px;
            }
        }
    }

    img {
        width: 100%;
    }
}
</style>